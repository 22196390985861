/*
Font size fallback
--------------------------------------------------*/
@mixin font-size($sizeValue: 1.6){
    font-size: ($sizeValue * 1) + px;
    font-size: (($sizeValue / 10) / 1.6) + rem;
}

// Fonts
@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");

// customize some Bootstrap variables
$primary: #3eae49;
$secondary: #ffae35;
$warning-color: #B15a1e;

// custom colors
$colorPrimaryBorder: #4e6c32;
$colorSecondaryHover: #ff9900;
$colorSeparator: #22932d;
$colorFooterBackground: #555555;
$colorFooterLinks: #f4f4f4;
$colorFooterLinkSeparator: #444444;
$colorBodyBackground: #FAFAFA;
$colorContentBackground: #FFFFFF;
$colorTextInverse: #FFFFFF;
$colorTextHeading: #666666;
$colorTextHeadingDarker: #444444;
$colorDisabledLink: #999999;

$colorDatepickerHeader: #22932d;
$colorDatepickerDarkText: #555555;

@import "~bootstrap/scss/bootstrap";
@import "~select2/dist/css/select2.min.css";

html {
  //  font-size: 62.5%;
}

body {
    background: $colorBodyBackground;
    font-family: tahoma, arial, helvetica, clean, sans-serif;
    @include font-size(14);
    line-height:18px;
    color:#333333;
    -webkit-font-smoothing:antialiased;
}

ul {
    list-style: none outside;
    margin-bottom: 18px;
    &.regions {
        padding-left: 0;
        margin-bottom: 0;
        a span {
            color: #cccccc;
        }
    }
}

ul.static-list {
    list-style: square;
}

h2 {
    font-weight: 600;
    margin-bottom: 15px;
    @include font-size(16);
}

a {
    text-decoration: none;
    line-height: inherit;
}

fieldset {
    @include media-breakpoint-up(md) {
        padding: 10px 20px;
    }
    .form-check-label {
        padding-left: 5px;
    }
    .col-form-label {
        font-weight: bold;
    }
    input[type=text], input[type=number], input[type=date], textarea {
        border-radius: 2px 2px 2px 2px;
        padding: 6px 3px 4px;
        border: 1px solid #bbbbbb;
        display: block;
    }
}

fieldset, .modal-content {
    label.error {
        display: block;
        color: #ff3333;
        font-weight: 600;
    }
}

input[type=text] {
    padding: 3px 5px 5px;
}

.btn {
    @include font-size(14);
}

.btn-lg {
    @include font-size(16);
}

.btn-primary {
    color: $colorTextInverse;
    border: 1px solid $colorPrimaryBorder;
    line-height: 1;
    font-weight: bold;
}

.btn {
    padding: 8px 20px 10px;
    &.btn-small {
        @include font-size(10);
        line-height: 1em;
        text-decoration: none;
        font-weight: bold;
    }
}

.btn-secondary {
    color: $colorTextInverse;
    line-height: 1.4em;
    font-weight: bold;
    border: 1px solid $colorSecondaryHover;
    &:hover {
        color: $colorTextInverse;
        background-color: $colorSecondaryHover;
    }
    &.btn-lg {
        @include font-size(16);
    }
}

.btn-outline-primary {
    background-color: #ededed;
    color: $colorTextHeading;
    padding: 8px 20px 10px;
    line-height: 1em;
    text-shadow: none;
    &.btn-lg {
        line-height: 1.4em;
    }

    &:hover {
        background-color: #cecece;
        color: $colorTextHeading;
    }
}

.pagination-area {
    .btn, .btn-outline-primary {
        @include media-breakpoint-down(sm) {
            padding: 8px 5px 10px;
        }
    }
}

input#fulltext-search {
   // min-width: 160px;
}

#header {

    background: url(../images/header_back_green.png) repeat-x bottom;
    height: 75px;
    @include media-breakpoint-down(sm) {
        height: 50px;
    }

    &.header-short {
        height: 66px;
        background-position: top;
        @include media-breakpoint-down(sm) {
            height: 41px;
        }
    }

    .logo-block {
        height: 123px;
        width: 450px;
        text-align: center;
        margin-left: -9em;
        background: url(../images/header_logo_back.png) no-repeat top;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 100;
        @include media-breakpoint-down(sm) {
            background-size: 75%;
            width: 372px;
        }
        img.logo {
            top: 0px;
            position: relative;
            width: 144px;
            height: 118px;
            @include media-breakpoint-down(sm){
                width: 100px;
                height: 74px;
            }
        }
    }
}

input[type=text].search-text {
    border: 1px solid #bbbbbb;
    border-radius: 0 0 2px 2px;
    border-right: 0;
}

form .nospite-background {
    background-color: white;
    img {
        width: 35px;
        height: 35px;
        border-top: 1px solid #bbbbbb;
        border-bottom: 1px solid #bbbbbb;
        border-right: 1px solid #bbbbbb;
        border-radius: 2px 2px 0 0;
        padding: 5px;
        margin-bottom: 0;
    }
}

.hp-search {
    min-height: 320px;
    margin-bottom: 2em;
    background: url(../images/board.jpg) repeat-x top left;
    background-size: 100% 100%;
    -webkit-box-shadow: rgb(0 0 0 / 25%) 0 5px 8px;
    -moz-box-shadow: rgba(0,0,0,0.25) 0 5px 8px;
    box-shadow: rgb(0 0 0 / 25%) 0 5px 8px;
    padding-bottom: 30px;
    @include media-breakpoint-up(md){
        padding-bottom: 0;
    }

    h1 {
        color: $colorTextInverse;
        @include font-size(42);
        font-weight: normal;
        text-shadow: 0 1px 2px #222222;
        font-family: 'Courgette', cursive, tahoma, arial, helvetica, clean, sans-serif;
        padding-top: 5.25rem;
        padding-bottom: 0.5rem;

        @include media-breakpoint-down(sm){
            @include font-size(32);
            padding-top: 1em;
        }
    }
    .teacher {
        width: 100%;
        @include media-breakpoint-up(md) {
            &.teacher1 {
                background: url(../images/illustration_teacher1.png) no-repeat bottom right;
            }
            &.teacher2 {
                background: url(../images/illustration_teacher2.png) no-repeat bottom right;
            }
            height: 400px;
        }
    }
    .filter {
        li {
            margin-bottom: 0.7em;
        }

        a#do_search {
            color: #ffffff;
         //   text-shadow: 0 1px 2px #222222;
        }

        .counter {
            color: #cccccc;
        }
    }
}

.nav-panel {
    background-color: $colorContentBackground;
    padding: 25px;
    border: 1px solid #cccccc;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 8px #dddddd inset;
    -khtml-box-shadow: 0 0 8px #dddddd inset;
    -moz-box-shadow: 0 0 8px #dddddd inset;
    box-shadow: 0 0 8px #dddddd inset;
    text-align: center;
    height: 100%;

    h3 {
        margin: 5px 0 0;
        font-weight: bold;
        @include font-size(18);
        color: $colorTextHeading;
        @include media-breakpoint-down(lg) {
            font-weight: 600;
            @include font-size(16);
        }
    }

    a:hover {
        h3 {
            color: black;
        }
    }

    .icon {
        height: 45px;
    }
}

footer {
    bottom: 0;
    display: block;
    width: 100%;
    background-color: $colorFooterBackground;
    border-top: 9px solid $colorSeparator;
    padding: 1em 0 1em;

    a, span {
        padding: 0.4em;
        display: inline-block;
        color: $colorFooterLinks;
    }

    span, span>a {
        padding: 0.4em 0;
    }

    a:hover {
        font-weight: 500;
    }

    a:hover, a:visited, a:active {
        color: $colorFooterLinks;
    }

    .links {
        margin-top: 0;
        margin-bottom: 0;
        padding: 0 1em 0 0.6em;

        li {
            border-bottom: 1px solid $colorFooterLinkSeparator;
        }
    }

    .partners {
        margin: 5px 10px 10px;
        color: $colorFooterLinks;
    }
}

.main-container {
    margin-top: 50px;
    padding: 20px;
    background-color: $colorContentBackground;
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 30%);
    box-shadow: 0 0 2px rgb(0 0 0 / 30%);

    border: 1px solid #cccccc;
}

.borderless-container {
    padding: 30px;
    background-color: $colorContentBackground;
}

.border-container {
    background-color: $colorContentBackground;
    -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 30%);
    box-shadow: 0 0 2px rgb(0 0 0 / 30%);

    border: 1px solid #cccccc;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 1em;
}

form#fulltextsearch {
    display: inline-block;
}

.header-right-part {
    margin-top: 20px;
    text-align: right;
    z-index: 500;

    @include media-breakpoint-down(md){
        margin-top: 4px;
        padding-right: 5px;
    }

    .admin-notification {
        color: green;
        display: inline-block;
    }
}

.static-container {
    h3 {
        background-color: #f4f4f4;
        margin-bottom: 20px;
        display: block;
        padding: 7px 15px;
        margin-top: 15px;
        &.light {
            background-color: #ffffcc;
            @include font-size(18);
        }
    }
    h4 {
        @include font-size(16);
    }
    img {
        width: 100%;
        margin-bottom: 10px;
        &.bordered {
            border: 1px solid black;
        }
    }
    dl {
        padding: 0 1em;
        margin-bottom: 2em;
    }
    dt {
        font-weight: bold;
    }
    dd {
        margin: 0.2em 0 0.4em 1.5em;
    }

    h4.underlined {
        //  line-height: 2em;
        border-bottom: 1px solid #0f7f0f;
        margin-top: 1em;
        @include font-size(26);
        margin-bottom: 3px;
        font-weight: bold;
        padding: 0;
        background: none;
    }
}

.text-notice {
    color: #ff3333;
}

.line-separator {
    border-bottom: 1px dashed #cccccc;
    margin: 1em 0;
}

.alert-box {
    display: block;
    padding: 6px 7px;
    font-weight: bold;
    @include font-size(14);
    background: #eee;
    border: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 12px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    text-align: center;

    .alert-text {
        margin: 25px 0;
    }
    .alert-button {
        margin-bottom: 20px;
    }
    &.alert-success {
        background-color: #d4edda;
        border-color: #c3e6cb;
    }
}

.job-offer {
    margin: 0.2em 0 1.4em;
    padding: 0;
    font-weight: normal;

    .title {
        a {
            @include font-size(16);
            font-weight: bold;
            &:visited {
                color: #99AF9B;
            }
        }
    }
    .position-type {
        margin: 0.4em 0 0;
        padding: 0.4em 0.3em 0.1em 0.4em;
        background-color: #f4f4f2;
        border-bottom: 1px solid #cccccc;
        color: #999999;
        @include font-size(13);
    }
}

.applicant-details {
    margin: 0.2em 0 1.4em;
    padding: 0;
    font-weight: normal;

    .phone-icon {
        width: 15px;
        height: 15px;
        top: 5px;
        position: relative;
    }

    .applicant-footer {
        margin: 0.4em 0 0;
        padding: 0.4em 0.3em 0.1em 0.4em;
        background-color: #f4f4f2;
        border-bottom: 1px solid #cccccc;
        color: #999999;
        @include font-size(14);
        text-align: right;

        .file-icon {
            background: none repeat scroll 0 0 #000000;
            //height: 10px;
            position: relative;
            width: 15px;
            color: #ffffff;
            text-align: center !important;
            margin-right: 10px;
            margin-top: 3px;
            //padding-bottom: 3px;
            display: inline-block;

            a {
                color: white;
            }

            &:before {
                border-bottom-width: 4px;
                border-bottom-style: solid;
                border-left: 0 none;
                border-right: 5px solid rgba(0, 0, 0, 0);
                content: "";
                height: 0;
                position: absolute;
                top: -4px;
                width: 15px;
                left: 0;
            }

            &.orange {
                background-color: #fcaf17;
                &:before {
                    border-bottom-color: #fcaf17;
                }
            }
            &.blue {
                background-color: #0066cc;
                &:before {
                    border-bottom-color: #0066cc;
                }
            }
            &.green {
                background-color: #0da115;
                &:before {
                    border-bottom-color: #0da115;
                }
            }


        }
    }
}

.actual-offers-header {
    line-height: 2em;
    border-bottom: 1px solid #0f7f0f;
    padding: 0.3em 0 1em 0;
    margin-top: 1em;
    margin-bottom: 0.7em;
    @include font-size(16);
    h2 {
        margin-bottom: 0;
        @include media-breakpoint-up(sm) {
            display: inline-block;
        }
    }
    div {
        font-weight: bold;
        @include media-breakpoint-up(sm) {
            float: right;
            display: inline-block;
        }
    }
}

.bottom-fixed-button {
    position: fixed;
    bottom: 0;
    background: #eaf5eb;
    width: 100%;
    text-align: center;
    z-index: 2000;
    padding: 13px;
    margin-top: 20px;
    box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.5);
    .btn {
        @include media-breakpoint-down(sm) {
            @include font-size(26);
            width: 100%;
        }
    }
}

// datepicker
.flatpickr-calendar {
    .flatpickr-months .flatpickr-month,
    .flatpickr-current-month .flatpickr-monthDropdown-months,
    .flatpickr-weekdays,
    span.flatpickr-weekday {
        background-color: $colorDatepickerHeader;
    }

    span.flatpickr-weekday {
        color: $colorDatepickerDarkText;
    }
}

.modal-content {
    padding: 15px;
    .modal-header {
        border-bottom: none;
    }
    .modal-footer {
        border-top: none;
    }
}

.form-check-label {
    margin-left: 10px;
}

body.admin-body {
    background: white;
}

.admin-navbar {
    background-color: #B2F394;
    a.nav-link {
        @include font-size(16);
        font-weight: 500;
    }
}
.admin-result-table {
    thead th {
        background-color: #e0e0e0;
    }
    &.table-bordered th,
    &.table-bordered td {
        padding: 5px 10px;
    }
}

.pagination-area {
    .btn {
        margin: 0 3px;
    }
}

.admin-found-count {
    margin-bottom: 15px;
    @include font-size(16);
    font-weight: 600;
}

.form-subject-group {
    display: none;
}

.select2-container--bootstrap4 .select2-selection--multiple li.select2-selection__choice {
    background-color: #e4e4e4;
    border: 1px solid #aaa;
    border-radius: 4px;
    display: inline-block;
    margin-left: 2px;
    margin-top: 1px;
    padding: 1px 3px 2px 0;

    button.select2-selection__choice__remove {
        border: none;
        @include font-size(13);
        background-color: #e4e4e4;
        color: #2e3136;
    }
}

.select2-container .select2-selection--multiple .select2-search--inline .select2-search__field {
    margin-left: 0;
    padding-left: 5px;
}

.select2-selection__rendered {
    line-height: 41px !important;
}
.select2-container .select2-selection--single {
    height: 43px !important;
}
.select2-selection__arrow {
    height: 0 !important;
}

input.form-input-date[readonly] {
    background-color: white;
    @include font-size(13);
}

div.search-filter-group {
    border-bottom: 1px solid #999999;
    background-color: #efefef;
    background-image: -webkit-gradient(linear,left 0%,left bottom,from(#efefef),to(#dadada));
    background-image: -webkit-linear-gradient(top,#efefef,#dadada);
    background-image: -moz-linear-gradient(50% 0%,#efefef,#dadada);
    background-image: -o-linear-gradient(top,#efefef,#dadada);
    background-image: -ms-linear-gradient(top,#efefef,#dadada);
    background-image: linear-gradient(top,#efefef,#dadada);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#efefef',EndColorStr='#dadada');

    line-height: 1.4rem;
    margin: 1em 0 0;
    padding: 0.7em 0.5em 0.5em 0.6em;
    @include font-size(14);
    font-weight: bold;
    text-transform: uppercase;
    color: #424242;
    width: 100%;
    text-align: left;
    cursor: pointer;

    .arrow {
        width: 0;
        height: 0;
        float: right;
        margin: 0.3em 0 0.6em;

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 6px solid #424242;
    }

    &[aria-expanded=true] .arrow {
        border-top: none;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 6px solid #424242;
    }
}

#search-criteria {
   // padding: 2px 2px 2px 2px;
    margin: 0;
    background-color: #ffffcc;
    padding: 0.5em;
    @include font-size(14);
    line-height: 18px;

    .criteria-title {
        font-weight: bold;
        padding: 5px 0;
    }

    ul.search-criteria-list {
        margin: 0.4em 0 0.8em 0.8em;
        list-style: none;
        padding-inline-start: 0;

        li {
            background: url(../images/ico_cross.png) no-repeat 100% 3px;
            a {
                padding-right: 2em;
                color: #333333;
                display: block;
                @include font-size(14);
            }
            a:hover {
                text-decoration: line-through;
            }
        }
    }
}

.search-filter-options ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;

    li {
        border-bottom: 1px solid #d5d5d5;
        background-color: #f9f9f9;
        background-image: -webkit-gradient(linear,left 0%,left bottom,from(#f9f9f9),to(#f0f0f0));
        background-image: -webkit-linear-gradient(top,#f9f9f9,#f0f0f0);
        background-image: -moz-linear-gradient(50% 0%,#f9f9f9,#f0f0f0);
        background-image: -o-linear-gradient(top,#f9f9f9,#f0f0f0);
        background-image: -ms-linear-gradient(top,#f9f9f9,#f0f0f0);
        background-image: linear-gradient(top,#f9f9f9,#f0f0f0);
        filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,StartColorStr='#f9f9f9',EndColorStr='#f0f0f0');

        padding: 0.6em 0.5em 0.6em 1.4em;

        a {
            span {
                color: #999999;
                float: right;
            }
        }
    }
}

.alphabet-top-links {
    line-height: 2em;
    border-bottom: 1px solid #0f7f0f;
    padding: 0.5em 0 0 0;
    margin-top: 1em;
    margin-bottom: 1.5em;
    @include font-size(14);

    ul {
        padding-inline-start: 0;
        margin-bottom: 5px;

        li {
            display: inline-block;
            a {
                font-weight: bold;
                color: #0f7f0f;
                &:hover {
                    color: #31a000;
                    text-decoration: underline;
                }
            }
        }
    }
}

.quick-options {
    margin-top: 2rem;

    .regions li {
        margin-bottom: 0.8em;
    }

    a {
        color: #ffffff;
        text-shadow: 0 1px 2px #222222;
        @include font-size(16);

        span {
            color: #cccccc;
        }
    }
}

.summary-links {
    margin-top: 10px;

    a.contrast-link {
        color: #f5ab17;
        text-shadow: 0 1px 2px #222222;
        @include font-size(16);
        span {
            color: #cccccc;
        }
    }
}

.alphabet-block {
    margin-bottom: 15px;
}

.alphabet-link-row {
    margin-bottom: 7px;
    a span {
        color: #333333;
    }
}

a.regions-county-link, a.regions-district-link {
    color: #0f7f0f;
    font-weight: bold;
    margin-bottom: 5px;
    line-height: 2em;

    span {
        color: #333333
    }
}

a.regions-district-link {
    margin-left: 20px;
}

.search-criteria-block {
    margin-bottom: 10px;
    //@include media-breakpoint-down(sm) {
    //    display: none;
    //}
}

.checkbox-padding {
    padding-left: 3.5rem;
}

fieldset .form-check-input[type=checkbox] {
    margin-left: -2rem;
}

span[data-toggle="tooltip"] {
    text-decoration: none;
}

.tooltip {
    .tooltip-inner {
        max-width: 100%;
        @include font-size(14);
        color: white;
        background-color: #313131;
        padding: 15px;
    }
}

h3.agent-status{
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 20px;
}

img.status-icon {
    width: 10%;
    margin-top: 25px;
}

.btn.disabled {
    cursor: not-allowed;
}

.filter-option-text {
    max-width: 85%;
    display: inline-block;
}

.flex-video {
    position: relative;
    padding-top: 25px;
    margin-bottom: 16px;
    overflow: hidden;
    height: 340px;
    width: 640px;
    @include media-breakpoint-down(md) {
        height: 240px;
        width: 360px;
        padding-top: 0;
    }
    @include media-breakpoint-down(sm) {
        height: 240px;
        width: 360px;
        padding-top: 0;
    }
}

.flex-video.vimeo { padding-top: 0; }

.flex-video iframe,
.flex-video object,
.flex-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.login-form-block {
 //   border: 5px solid $primary;
    margin-top: 30px;
    margin-bottom: 40px;
    padding: 20px;
    background-color: $colorBodyBackground;
}

.primary-link {
    font-weight: bold;
    padding: 0 10px;
    &.disabled {
        color: $colorDisabledLink;
    }
}

.danger-link {
    font-weight: bold;
    padding: 0 0 0 10px;
    color: $warning-color;
    &:hover, &:visited, &:active {
        color: darken($warning-color, 10%);
    }
}

img.account-icon {
    height: 12px;
    width: 12px;
    position: relative;
    bottom: -4px;
}

.primary-link.normal-right {
    @include font-size(14);
    float: right;
    padding-top: 3px;
}

span.warning-text {
    color: $warning-color;
}

.form-control {
    @include font-size(14);
}

.system-message {
    font-style: italic;
    color: gray;
}

ol {
    counter-reset: item;
}

ol > li {
    counter-increment: item;
}

ol ol > li {
    display: block;
}

ol ol > li:before {
    content: counters(item, ".") ". ";
    margin-left: -20px;
}

@include media-breakpoint-down(sm) {
    .primary-link, .btn, .btn.btn-primary, .btn.btn-secondary {
        @include font-size(14);
    }
    h2, .job-offer, .job-offer .title a {
        @include font-size(16);
    }
    .job-offer .position-type {
        @include font-size(13);
    }
    .bottom-fixed-button a.btn.btn-primary {
        @include font-size(26);
    }
}

body.has-mobile-action-button a.qc-cmp2-persistent-link {
    bottom: 72px;
}

.search-results {
    h1 {
        @include font-size(26);
    }
}

form#advanced_search {
    padding-top: 15px;
    padding-bottom: 5px;
}

#basic-addon2 {
    padding: 0 0.75rem;
}